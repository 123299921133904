import Image from 'next/image'

import _validators from '@/utils/validators'

import { Button, Container, Input, List, Tag, Title } from '@/components'

import ConsultationImg from '/public/images/consultation.webp'

import { useConsultation } from './useConsultation'

import styles from './Consultation.module.scss'

export const Consultation = () => {
  const { control, isValid, isLoading, onSubmit, handleSubmit } =
    useConsultation()

  return (
    <section id="consulta-cnpj" className={styles.consultation}>
      <Container>
        <Tag text="Consulta Score CNPJ grátis" />

        <Title
          customClass={styles.consultation__title}
          text={
            <>
              Precisa de <b>informações sobre o seu CNPJ</b>? <br /> Consulte o{' '}
              <b>Score CNPJ</b> da sua empresa de forma rápida, simples e{' '}
              <b>gratuita</b>
            </>
          }
        />

        <div className={styles.consultation__wrapper}>
          <div>
            <Image
              loading="lazy"
              src={ConsultationImg}
              alt="tablet mostrando o relatório de consulta"
              style={{
                width: '100%',
                height: 'auto'
              }}
            />
          </div>

          <div>
            <p className={styles.consultation__text}>
              O Score reflete a saúde financeira da sua empresa e é importante
              para:
            </p>

            <List
              items={[
                'Garantir boas oportunidades de créditos e empréstimos',
                'Negociações comerciais',
                'Abertura de contas bancárias e serviços financeiros',
                'Avaliação de riscos'
              ]}
            />

            <form
              className={styles.consultation__form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <p className={styles.consultation__formTitle}>
                Consulte agora seu Score
              </p>

              <Input.Cnpj
                id="cnpj"
                name="cnpj"
                label="CNPJ da minha empresa"
                placeholder="XX.XXX.XXX/0001-XX"
                control={control}
                rules={{
                  required: 'Campo obrigatório',
                  validate: (value) => {
                    _validators.cnpj(value) || 'CNPJ inválido'
                  }
                }}
              />

              <Button
                type="submit"
                id="consultation-consult-button"
                disabled={!isValid}
                isLoading={isLoading}
              >
                Consultar CNPJ grátis
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </section>
  )
}
